// 2022 3151 广东-深圳
// export const sid = '3151';
// export const formid = '60';
// export const sidsponsorhistory = '48'; // 历史参会企业
// export const sidsponsor = '243';   // 赞助商
// export const sidlecturer1 = '240'; // 联席主席
// export const sidlecturer2 = '241'; // 出品人
// export const sidlecturer3 = '244'; // 选题委员会
// export const sidcase = '242';      // 精选案例
// export const feedbackPopId = '262';   // 赞助商

var id_list = {
  '2025sz': {
    session: '十二', 
    year: '2025', 
    sid: '4497', 
    address: '深圳', 
    haslive: false, // 是否进行直播
    formurl: '', // 'https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId= ', // 咨询表单
    formid: '97', // 咨询表单 // https://acrm.msup.com.cn/form/detail?form_id=
    caseid: '', // 案例征集
    caseurl: '', // 案例征集表单
    livevod: 0, // 有直播时是否使用回放模式（会议结束后确定视频后用1，直播前和直播中用0）
    livetype: 0, // 直播类型（0-直播助手无聊天室，1-线上直播有聊天室）
    // themeProducers: true, // 是否从专题列表获取出品人列表
    // 
    sueid: { 
      sidlecturer1: '408',    // 联席主席
      sidlecturer2: '409',    // 出品人
      sidlecturer3: '407',    // 选题委员会
      sidcase: '406',         // 精选案例
      sidsponsor: '405',      // 赞助商
      feedbackPopId: '263',   // 赞助商(直播反馈)
      sidsponsorhistory: '48', // 历史参会企业
      sidsponsorunite : '', // 联合主办方-2022/2023-329
      sidopening: '410', // 开幕式
    },
    sponsorimg: '', // 联合主办方
    sponsorcontent: {}, // 替换为推荐位获取分类 { '钻石赞助':[], '银牌赞助':[], '铜牌赞助':[], '礼品赞助': [], '大会支持单位':[], '合作推广伙伴': [], }, // '金牌赞助': [], '银牌赞助': [], '铜牌赞助': [],  '图书赞助': [],  '高级赞助': [], 
    scheduledifference: { 
      // '2024sz_1_6_pm':{ length:6, courses:[], linkUrl:'https://www.msup.com.cn/site/clause-activity?id=3784' } 
    }, // 日程专题排课存在差异的场次(如2024届Day1下午第6专题有6场排课,有别于其他专题的4场排课)
    // cardid: { subject:12, course:14, schedule:10, case:13, casedetailid:192 }
  },
  '2024sz': {
    session: '十一',
    year: '2024', 
    sid: '4105', 
    address: '深圳', 
    haslive: false, // 是否进行直播
    formurl: '', // 'https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId= ', // 咨询表单
    formid: '85', // 咨询表单 // https://acrm.msup.com.cn/form/detail?form_id=
    caseid: '', // 案例征集
    caseurl: '', // 案例征集表单
    livevod: 0, // 有直播时是否使用回放模式（会议结束后确定视频后用1，直播前和直播中用0）
    livetype: 0, // 直播类型（0-直播助手无聊天室，1-线上直播有聊天室）
    sueid: { 
      sidlecturer1: '358',    // 联席主席
      sidlecturer2: '359',    // 出品人
      sidlecturer3: '360',    // 选题委员会
      sidcase: '361',         // 精选案例
      sidsponsor: '357',      // 赞助商
      feedbackPopId: '263',   // 赞助商(直播反馈)
      sidsponsorhistory: '48', // 历史参会企业
      sidsponsorunite : '329', // 联合主办方
    },
    sponsorimg: '', // 联合主办方
    sponsorcontent: { '钻石赞助':[], '银牌赞助':[], '铜牌赞助':[], '礼品赞助': [], '大会支持单位':[], '合作推广伙伴': [], }, // '金牌赞助': [], '银牌赞助': [], '铜牌赞助': [],  '图书赞助': [],  '高级赞助': [], 
    scheduledifference: { 
      '2024sz_1_6_pm':{ length:6, courses:[], linkUrl:'https://www.msup.com.cn/site/clause-activity?id=3784' } 
    }, // 日程专题排课存在差异的场次(如2024届Day1下午第6专题有6场排课,有别于其他专题的4场排课)
    // cardid: { subject:12, course:14, schedule:10, case:13, casedetailid:192 }
  },
  '2023sz': {
    session: '十',
    year: '2023', 
    sid: '3647', 
    address: '深圳', 
    haslive: false, // 是否进行直播
    formurl: '', // 'https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId=2905', // 咨询表单
    formid: '81', // '73', // 咨询表单 // https://acrm.msup.com.cn/form/detail?form_id=81
    caseid: '', // 案例征集
    caseurl: '', // 案例征集表单
    livevod: 0, // 有直播时是否使用回放模式（会议结束后确定视频后用1，直播前和直播中用0）
    livetype: 0, // 直播类型（0-直播助手无聊天室，1-线上直播有聊天室）
    sueid: { 
      sidlecturer1: '314',    // 联席主席
      sidlecturer2: '315',    // 出品人
      sidlecturer3: '316',    // 选题委员会
      sidcase: '317',         // 精选案例
      sidsponsor: '318',      // 赞助商
      feedbackPopId: '263',   // 赞助商(直播反馈)
      sidsponsorhistory: '48', // 历史参会企业
      sidsponsorunite : '329', // 联合主办方
    },
    sponsorimg: '', // 联合主办方
    // cardid: { subject:120, course:140, schedule:100, case:130, },
    sponsorcontent: { '高级赞助': [], '礼品赞助': [], '合作推广伙伴': [], }, // '金牌赞助': [], '银牌赞助': [], '铜牌赞助': [],  '图书赞助': [],
  },
  '2022sh': {
    session: '九',
    year: '2022', 
    sid: '3303', 
    address: '上海', 
    haslive: true, // 是否进行直播
    formurl: '', // 咨询表单 'https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId=2905',
    formid: '73', // '66', // 咨询表单
    caseid: '2874', // 案例征集
    caseurl: 'http://p.msup.cn/Y9Kx', // 案例征集表单 'https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId=3087',
    livevod: 1, // 有直播时是否使用回放模式（会议结束后确定视频后用1，直播前和直播中用0）
    livetype: 0, // 直播类型（0-直播助手无聊天室，1-线上直播有聊天室）
    sueid: { 
      sidsponsorhistory:'48', // 历史参会企业
      sidsponsor: '266',      // 赞助商
      sidlecturer1: '267',    // 联席主席
      sidlecturer2: '268',    // 出品人
      sidlecturer3: '269',    // 选题委员会
      sidcase: '270',         // 精选案例
      feedbackPopId: '263',   // 赞助商
    },
    sponsorimg: '', // 联合主办方
    sponsorcontent: { '金牌赞助': [], '银牌赞助': [], '铜牌赞助': [], '合作推广伙伴': [], }, // '图书赞助': [], 
  },
  '2022sz': {
    session: '八',
    year: '2022', 
    sid: '3151', 
    address: '深圳', 
    haslive: true, // 是否进行直播
    formurl: '', // 咨询表单
    formid: '60', // 咨询表单
    caseid: '2665', // 案例征集
    caseurl: 'http://p.msup.cn/jTH0',  // 案例征集表单 'https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId=2665',
    livevod: 0, // 有直播时是否使用回放模式
    livetype: 0, // 直播类型（0-直播助手无聊天室，1-线上直播有聊天室）
    sueid: { 
      sidsponsorhistory:'48', // 历史参会企业
      sidsponsor: '243',      // 赞助商
      sidlecturer1: '240',    // 联席主席
      sidlecturer2: '241',    // 出品人
      sidlecturer3: '244',    // 选题委员会
      sidcase: '242',         // 精选案例
      feedbackPopId: '263',   // 赞助商 262
    },
    sponsorimg: '', // 联合主办方
    sponsorcontent: { '银牌赞助': [], '铜牌赞助': [], '大会指定机票 酒店合作平台': [], '战略合作伙伴': [], '合作推广伙伴': [], }, 
  },
  '2021': {
    session: '七',
    year: '2021', 
    sid: '2682', 
    address: '深圳', 
    haslive: false, 
  }
};

// export const year = '2022sz';
// export const sid = '3151';
export const id_arr = {...id_list};
export const sidyear = (sid)=>{return Object.keys(id_list).find(key=>(id_list[key].sid&&id_list[key].sid.toString().trim()) === (sid&&sid.toString().trim()));};
export const session = (year)=>{return id_list[year].session;};
export const sid = (year)=>{return id_list[year].sid;};
export const haslive = (year)=>{return id_list[year].haslive}
export const formid = (year)=>{return id_list[year].formid;};
export const _formurl = (year)=>{return id_list[year].formurl;};
export const caseid = (year)=>{return id_list[year].caseid;};
export const caseurl = (year)=>{return id_list[year].caseurl;};
export const livevod = (year)=>{return id_list[year].livevod};
export const livetype = (year)=>{return id_list[year].livetype};
export const sidsponsorhistory = (year)=>{return id_list[year].sueid.sidsponsorhistory}; // 历史参会企业
export const sidsponsor = (year)=>{return id_list[year].sueid.sidsponsor};   // 赞助商
export const sidlecturer1 = (year)=>{return id_list[year].sueid.sidlecturer1}; // 联席主席
export const sidlecturer2 = (year)=>{return id_list[year].sueid.sidlecturer2}; // 出品人
export const sidlecturer3 = (year)=>{return id_list[year].sueid.sidlecturer3}; // 选题委员会
export const producersFromTheme = (year)=>{return id_list[year].themeProducers || false}; // 是否从专题列表获取出品人列表
export const sidcase = (year)=>{return id_list[year].sueid.sidcase};      // 精选案例
export const sidopening = (year)=>{return id_list[year].sueid.sidopening || ''}; // 开幕式
export const feedbackPopId = (year)=>{return id_list[year].sueid.feedbackPopId}; // 赞助商
export const sidsponsorunite = (year)=>{return id_list[year].sueid.sidsponsorunite}; // 联合主办方
export const sponsorimg = (year)=>{return id_list[year].sponsorimg};      // 联合主办方
export const sponsorcontent = (year)=>{return id_list[year].sponsorcontent};     // (首页)赞助商类型
export const scheduledifference = (year)=>{return id_list[year].scheduledifference || false}; // 日程页排课存在差异的专题场次
export const cardIds = (year)=>{return id_list[year].cardid || false}; // 


// special id
export const scheduleDifferenceId = 377; // 推荐位-特殊排课专题
export const cardSueId = 378; // 推荐位-存储卡片相关图片
export const cardSueType = { Subject: ['282','专题课图'] } // 推荐位-存储卡片类型
export const cardKeyword = { subject: '专题', course: '课程', case: '案例', schedule: '日程', chairman: '主席', producer: '出品人' } // 卡片关键字
// url
export const qiniuDomain = 'https://f.msup.com.cn/'; // 七牛云域名
export const getUploadToken = 'https://acrm.msup.com.cn/api/upload'; // 七牛云上传图片用的token
export const formUrl = 'https://acrm.msup.com.cn/form/detail?form_id='; // 表单-峰会咨询
export const caseUrl = 'https://vip.msup.com.cn/index.php/weixin/feedback/form-feedback?formId='; // 表单(报名/案例征集)
export const getMeetingTime = 'https://www.msup.com.cn/api/api-common/get-simple-scheduling-data?sid=';
export const getAllCourse = 'https://api.msup.com.cn/scheduling/get-all-courses/'
export const getThemeInfo = 'https://api.msup.com.cn/group/get-group-project/' // 议题设置(演讲专题)
export const getScheduleInfo = 'https://api.msup.com.cn/scheduling/get-schedule/' // 会议日程
export const getScheduleInfoFinal = 'https://api.msup.com.cn/scheduling/get-schedule-final/' // 会议日程
export const getSpeechInfo = 'https://api.msup.com.cn/group/get-group-project/' // 演讲专题
export const getSpeechInfoNew = 'https://api.msup.com.cn/group/get-group-project-new/' // 演讲专题
export const getSpeechInfoFin = 'https://api.msup.com.cn/group/get-group-project-final/' // 演讲专题(包含详细producerInfo)
export const getSpeechTheme = 'https://api.msup.com.cn/group/get-group-project/' // 演讲专题
export const getSpeechThemeInfo = 'https://api.msup.com.cn/group/group-project-info/' // 演讲专题(producers)
export const getScheduleDetail = 'https://api.msup.com.cn/course/view/' // 获取课程详情
export const getCourseDetail = 'https://www.msup.com.cn/wechat/small-program/course-and-ppt' // 课程详情 ?sid=3917&cid=17363&token=sv_htD_W9CG_poeux1hWjODfxcEZTBJ1
export const getCoursePPT = 'https://www.msup.com.cn/api/api-common/get-course-ppt-universal' // 课程课件
export const getCardIdInfo = 'https://www.msup.com.cn/api/api-common/get-card-title-by-scheduling-id' // 卡片(海报)cardId
export const getCardInfo = 'https://www.msup.com.cn/api/api-common/get-card-info' // 卡片(海报)信息
export const getTicket = 'https://www.msup.com.cn/api/api-common/tickets-info' // 获取门票信息
export const getDiys = 'https://www.msup.com.cn/api/api-common/get-diys-data?hash=' // 获取渠道码对应优惠门票
export const createOrder = 'https://www.msup.com.cn/api/api-common/create-order-of-top' // 生成订单
export const createOrderGoods = 'https://www.msup.com.cn/api/api-common/create-order-and-order-goods' // 生成订单-qd
export const payStands = 'https://www.msup.com.cn/shopping/default/pay-stands' // 订单支付平台
export const sendCaptcha = 'https://www.msup.com.cn/login/default/send-captcha?phone=' // 购票-渠道-发送验证码
export const getUserInfo = 'https://www.msup.com.cn/api/top-api/user-set' // 获取用户个人信息
export const getMyTicketUniversal = 'https://www.msup.com.cn/api/api-common/get-ticket-universal' // 购买的门票/全部门票
export const getMyTicket = 'https://www.msup.com.cn/api/api-common/get-all-activation-ticket' // 个人拥有的门票(已激活)
export const getBuyTicket = 'https://www.msup.com.cn/api/api-common/get-all-ticket' // 个人购买的门票
export const getUserOrder = 'https://www.msup.com.cn/api/top-api/get-order' // 个人的订单
export const getUserOrderUniversal = 'https://www.msup.com.cn/api/api-common/get-order-universal' // 个人的订单
export const openInvoice = 'https://www.msup.com.cn/api/top-api/apply-invoice' // 开具发票
export const applyInvoice = 'https://www.msup.com.cn/api/api-common/apply-invoice-universal' // 开具发票(新申请方法)
export const checkHash = 'https://www.msup.com.cn/api/api-common/check-order-diys-by-params' // 判断hash是否有效
export const getSue = 'https://api.msup.com.cn/sue/get-all-sue-info/'; // 推荐位、广告位
export const getSueArr = 'https://api.msup.com.cn/sue/obtain-sue-by-arr/';
export const getSueType = 'https://api.msup.com.cn/sue/get-sue-type/';
export const getSueLecturer = 'https://api.msup.com.cn/sue/obtain-sue-lecturer/'; // 推荐位-讲师
export const getLecturerList = 'https://api.msup.com.cn/lecturer/get-all-lecturer-by-sid/' //  演讲嘉宾
export const getLecturerInfo = 'https://api.msup.com.cn/lecturer/view' // 嘉宾信息
// export const getSueInfo = 'https://api.msup.com.cn/sue/get-all-sue-info/243' // 赞助商
// export const getSueLecturer1 = 'https://api.msup.com.cn/sue/obtain-sue-lecturer/240' // 联席主席
// export const getSueLecturer2 = 'https://api.msup.com.cn/sue/obtain-sue-lecturer/241' // 出品人
// export const getSueLecturer3 = 'https://api.msup.com.cn/sue/obtain-sue-lecturer/242' // 精选案例
// export const getSueLecturer4 = 'https://api.msup.com.cn/sue/obtain-sue-lecturer/244' // 选题委员会
