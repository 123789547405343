<script type="text/javascript">
import $ from 'jquery'

let year = "2025sz";
let companyphone = "400-8128-020";
// let companyphone = "139-2085-9305";


const numberList = [ '一', '二', '三', '四', '五', '六', '七', '八', '九', '十' ];

// 正则
const regs = {
 phone: [/^1\d{10}$/, /^[1][1-9][0-9]{9}$/],
 email: /^([a-zA-Z0-9]+[_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/gi,
};

let hashParam = {};
let popupClose = false;
let popupTime = false;
let popupCloseLive = false;
let popupTimeLive = false;

function getHash() {
  if(window.location.search.length>0){
    window.location.search
    .substr(1)
    .split("&")
    .map(item => {
      const arr = item.split("=");
      hashParam[arr[0]] = arr[1];
    });
    localStorage.setItem('giac_hash', JSON.stringify(hashParam));
  }
  // console.log(hashParam);
}

function getServerTime() {
    return new Date($.ajax({
        type: 'get',
        url: '/',
        async: false
    }).getResponseHeader("Date"));
}

function formatString (str) {
  str = str ? str.toString().trim() : str;
  return (
    [undefined, null, false, 0, NaN, 'undefined', 'null', 'false', '0', '[]', '{}', '无', '暂无'].includes(str) ||
    /^\s*$/.test(str)
  ) ? '' : str;
}
function formatNumber(n) {
  n = n.toString();
  return n[1] ? n : "0" + n;
}
/* function formatTime(number, format, monthlength) {
  var formateArr = ["Y", "M", "D", "h", "m", "s"];
  var returnArr = [];
  var date = new Date(number * 1000); // 接口获取的数据一般为时间戳的1/1000
  returnArr.push(date.getFullYear());
  if(monthlength && monthlength==1) {
    returnArr.push(date.getMonth() + 1);
  } else {
    returnArr.push(formatNumber(date.getMonth() + 1));
  }
  returnArr.push(formatNumber(date.getDate()));
  returnArr.push(formatNumber(date.getHours()));
  returnArr.push(formatNumber(date.getMinutes()));
  returnArr.push(formatNumber(date.getSeconds()));
  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i]);
  }
  return format;
} */
function formatTime(stamp, format, hourLength) {
  var formateArr = ['Y', 'M', 'D', 'h', 'm', 's']
  var returnArr = []
  var date = 0
  var number = stamp || new Date().getTime()
  // if (number && number.toString().trim().length < 13){
  //   date = new Date(number * 1000) // 接口获取的数据一般为时间戳的1/1000
  // } else {
  //   date = new Date(number)
  // }
  if (number && number.toString().trim().length > 0) {
    if (number.toString().trim().length < 13) {
      date = new Date(number * 1000) // 接口获取的数据一般为时间戳的1/1000
    } else {
      date = new Date(number)
    }
  // } else { // 未传入时间戳时获取当前时间
  //   number = new Date()
  }
  let beijingYear = date.getFullYear() // 当前时区的年份
  let beijingMonth = date.getMonth() + 1 // 当前时区的月份
  let beijingDate = date.getDate() // 当前时区的日期
  // 获取UTC时间的小时数：北京时间是UTC+8，所以加上8小时，并处理跨日的情况（时区相差最多12小时故最多只会差1日，北京时间date不会小于UTC时间date）
  const utcHours = date.getUTCHours()
  const utcDate = date.getUTCDate()
  let baseHours = utcHours + 8 // 北京时间小时数，date.getHours()为当前时区的小时数
  let beijingHours = baseHours
  if (baseHours >= 24) { // 小时数跨日，-24
    beijingHours = baseHours - 24
    if (beijingDate <= utcDate) {
      beijingDate += 1
    }
  }
  if (utcDate > beijingDate) {
    beijingDate = utcDate
  }
  // 判断是否跨月：baseHours大于24且date大于当月最大值即为跨月（最多只会差1月）
  const monthsWith31Days = [1, 3, 5, 7, 8, 10, 12]
  let monthLength = 30 // 默认为30天长度
  if (beijingMonth === 2) {
    monthLength = 28 // 2月长度
    if (isLeapYear(beijingYear)) {
      monthLength = 29 // 闰年2月长度
    }
  } else if (monthsWith31Days.includes(beijingMonth)) {
    monthLength = 31 // 31天的月份的长度
  }
  if (baseHours >= 24 && beijingDate > monthLength) {
    beijingDate = 1
    beijingMonth += parseInt(beijingDate / monthLength)
  }
  // 判断是否跨年：month大于12即为跨年（最多只会差1年）
  if (beijingMonth > 12) {
    beijingMonth = beijingMonth % 12
    beijingYear += parseInt(beijingMonth / 12)
  }
  // console.log(number, utcHours, ' || ', beijingHours, beijingDate, beijingMonth, beijingYear)
  // 返回结果：年月日时分秒
  returnArr.push(beijingYear)
  returnArr.push(beijingMonth)
  returnArr.push(formatNumber(beijingDate))
  if (!hourLength || hourLength > 1) {
    returnArr.push(formatNumber(beijingHours))
  } else {
    returnArr.push(beijingHours)
  }
  returnArr.push(formatNumber(date.getMinutes()))
  returnArr.push(formatNumber(date.getSeconds()))
  for (var i in returnArr) {
    format = format.replace(formateArr[i], returnArr[i])
  }
  return format
}
function formatThumbs(thumbs, index) {
  let url = false;
  if(thumbs && thumbs.length){
    if(!thumbs.includes('{') && thumbs.includes('http')){
      url = thumbs;
    } else if(!['[]','{}'].includes(thumbs) && JSON.parse(thumbs)){
      let files = JSON.parse(thumbs)
      if (isArrayIndexs(files)) {
        url = (files[index || 0] && files[index || 0].fileUrl) || '';
      } else if (isObjectKeys(files)) {
        url = files.fileUrl || ''
      }
    }
  }
  if (url && url.length > 0) {
    url = url.replaceAll('http://', 'https://')
  }
  return url;
}

// 星期
function getWeek(number) {
  var date = new Date(number * 1000); // 接口获取的数据一般为时间戳的1/1000
  var weekArr = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"];
  return weekArr[date.getDay()];
}
// 闰年
function isLeapYear (year) {
  // 如果年份能被4整除但不能被100整除，或者能被400整除，则为闰年
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0)
}

function getSrc(img,cut){
  let url = img;
  // if(cut){
  //  url = 'http://vip.msup.com.cn/index.php/xin-bin-api/head?url='+escape(img)+'&size=205x205';
  // }
  // let support = '?imageView2/1/w/200/h/200/q/100';
  let support = '?imageMogr2/auto-orient/thumbnail/!600x600r/gravity/North/crop/600x600/blur/1x0/quality/100';
  // let support = '?imageMogr2/auto-orient/thumbnail/600x600/gravity/Center/crop/420x420/blur/1x0/quality/100';
  // let support = '?imageMogr2/gravity/North/crop/3072x3072/blur/1x0/quality/100';
  // url = (img+"").indexOf('?')!=-1?img:(img+support)
  url = (img+"").indexOf('?')!=-1 ? (img.split('?')[0]+support) : (img+support);
  return url;
}

function IsPC() {
  var userAgentInfo = navigator.userAgent;
  var Agents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  var flag = true;
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  return flag;
}

// 随机数
function setRandom(min, max) {
  var num = parseInt(Math.random()*100);
  if(num<min || num>max){
    // console.log('<<< ', num);
    return setRandom(min, max);
  } else {
    // console.log('>>> ', num);
    return num;
  }
}

// 对象组成的数组排序 自定义规则 arr.sort(toSortKey(key, mode, type, primer))   eg: arr.sort(global.toSortKey('sort',1,'string'))
function toSortKey(key, mode, type, primer) {
  /* 
      key    排序用的键名
      mode   1升序 0降序
      type   键值数据类型
      primer 数据的解析，如parseInt
  */
  return function(a, b) {
    a = a[key];
    b = b[key];
    // 避免存在值为null的情况
    if (a && b) {
      // 字符串排序
      if (type == "string") {
        if (mode) {
          return a.localeCompare(b);
        } else {
          return b.localeCompare(a);
        }
      }
      // 数字排序
      if (type == "number") {
        mode = mode ? -1 : 1;
        if (typeof primer != "undefined") {
          a = primer(a);
          b = primer(b);
        }
        if (a < b) {
          return mode * -1;
        }
        if (a > b) {
          return mode * 1;
        }
        return 1;
      }
    }
  };
}

// Object比较
/* function toCompareObject(obj1, obj2) {
  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);
  if(keys1.length != keys2.length) return false;
  for(let k of keys1){
    if (typeof obj1[k] === 'object' && obj1[k] !== null) {  // 如果是对象，则再递归对比，如果递归返回false，则直接方法也直接返回false
      if (!toCompareObject(obj1[k], obj2[k])) return false;
    } else if (typeof obj1[k] === 'function' || typeof obj2[k] === 'symbol') { // 如果是function或symbol，转字符串再对比，不匹配则直接返回false
      if (String(obj1[k]) !== String(obj2[k])) return false;
    } else {  // 最后其他类型用es6得Object.is()来比较，不匹配直接返回false
      if (!Object.is(obj1[k], obj2[k])) return false;
    }
  }
  return true;  // 遍历结束了没有返回false，说明没有问题，这里直接返回true，表示键值全等了
} */
function toCompareObject(obj1, obj2) {
  // console.log(obj1, obj2, typeof obj1, typeof obj2)
  if (typeof obj1 !== typeof obj2) {
    return false
  }
  // Object / Array
  if (typeof obj1 === 'function' || typeof obj1 === 'symbol') {
    if (String(obj1) !== String(obj2)) return false;
  } else if (typeof obj1 === 'object' && obj1 !== null) {
    let keys1 = Object.keys(obj1);
    let keys2 = Object.keys(obj2);
    if(keys1.length != keys2.length) return false;
    for(let _k of keys1){
      let k = Array.isArray(obj1) ? parseInt(_k) : _k
      if (typeof obj1[k] === 'object' && obj1[k] !== null) {  // 如果是对象，则再递归对比，如果递归返回false，则直接方法也直接返回false
        if (!toCompareObject(obj1[k], obj2[k])) return false;
      } else if (typeof obj1[k] === 'function' || typeof obj2[k] === 'symbol') { // 如果是function或symbol，转字符串再对比，不匹配则直接返回false
        if (String(obj1[k]) !== String(obj2[k])) return false;
      } else {  // 最后其他类型用es6得Object.is()来比较，不匹配直接返回false
        if (!Object.is(obj1[k], obj2[k])) return false;
      }
    }
  } else {
    if (!Object.is(obj1, obj2)) {
      return false
    }
  }
  return true;  // 遍历结束了没有返回false，说明没有问题，这里直接返回true，表示键值全等了
}

// 判断 Array Object
function isObject(o) {
  return (typeof o === 'object' || typeof o === 'function') && o !== null
}
function isOArray (a) {
  return typeof a === 'object' && Array.isArray(a)
}
function isObjectKeys(o) {
  return o && typeof o === "object" && !Array.isArray(o) && Object.keys(o) && Object.keys(o).length > 0;
}
function isArrayIndexs (a) {
  return a && isOArray(a) && a.length > 0
}
// NaN判断
function isReallyNaN (value) {
  let base = value
  let flag = false
  if (typeof value === 'number' && value !== base) {
    flag = true
  }
  return flag
}
// true/false判断（false null undefined NaN 0 '' [] {}）
function isTrue(variate, tag) {
  // variate                 :  boolean symbol function object array null undefined string number 
  // typeof variate          :  boolean symbol function object            undefined string number 
  // variate.constructor.name:  Boolean Symbol Function Object Array x    x         String Number 
  let flag = Boolean(variate);
  if(flag && typeof variate=='object'){
    flag = Object.keys(variate)&&Object.keys(variate).length>0 ? true : false;
  }
  return flag;
}
// 空数据
function isBlank (value) {
  let flag = Boolean(value)
  if (flag && (!isObjectKeys(value) || !isArrayIndexs(value))) {
    flag = false
  }
  return flag
}

// 深拷贝对象与数组
function deepClone(obj) {
  if (!isObject(obj)) {
      // throw new Error('obj 不是一个对象！')
      return obj;
  }
  // 迭代递归
  let isArray = Array.isArray(obj) //数组也是对象，Arry也是引用类型
  let cloneObj = isArray ? [] : {}
  for (let key in obj) {
     if(typeof obj[key] === 'function'){
          cloneObj[key] = new Function('return '+obj[key].toString())();
      }else{
          cloneObj[key] = isObject(obj[key]) ? deepClone(obj[key]) : obj[key];
      }
  }
  return cloneObj;
  // 序列化反序列化法 object or array
  // return JSON.parse(JSON.stringify(obj));
}

// 转换Blob对象
function dataURLToBlob(dataurl) {
  // console.log('👉🏻url ', dataurl);
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

// 转换base64
function tirggerFile(_file) {
  return new Promise(function(resolve, reject) {
    var reader = new FileReader();
    var file = _file;
    var url = "";
    var img_url = "";
    reader.readAsDataURL(file);
    // reader.readAsArrayBuffer(file);
    reader.onload = async function (e) {
      url = this.result.substring(this.result.indexOf(",") + 1);
      img_url = "data:image/png;base64," + url;
      // resolve(this.result);
      resolve(img_url);
    };
  })
}

// 一键复制文本
function copyText(text) {
  return new Promise(async (resolve, reject) => {
    if('clipboard' in navigator) {
      // console.log("navigator.clipboard.writeText('xxx')"); // new-main
      (async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          console.log('writeText 复制成功');
          resolve(true);
        } catch (err) {
          console.error('writeText 复制失败: ', err);
          resolve(false);
        }
      })(text);
    } else if(isCommandSupported('copy')) {
      // console.log("slect dom -> document.execCommand('copy')"); // limit
      if(document.createRange) {
        // console.log("slect dom —— selectNode"); // limit-new
        const copyDom1 = document.createElement("a");
        copyDom1.innerHTML = text;
        copyDom1.display = 'none';
        document.body.appendChild(copyDom1);
        window.getSelection().removeAllRanges();
        let range = document.createRange();
        range.selectNode(copyDom1);
        window.getSelection().addRange(range);
        await toCopy(text);
        document.body.removeChild(copyDom1);
        window.getSelection().removeAllRanges();
      } else {
        // console.log("slect dom —— select —— createTextRange/select"); // limit--old/new
        const copyDom = document.createElement("textarea");
        copyDom.value = text;
        copyDom.display = 'none';
        if (document.body.createTextRange) { // only IE5-8
          var textRange = document.body.createTextRange();
          textRange.moveToElementText(copyDom);
          textRange.select();
        } else if (copyDom.select) { // IE9+ and more
          copyDom.focus();
          copyDom.select();
        }
        await toCopy(text);
        document.body.removeChild(copyDom);
      }
      function toCopy (text) {
        console.log(text)
        try {
          document.execCommand('copy');
          console.log('copy 复制成功');
          resolve(true);
        } catch (err) {
          console.error('copy 复制失败: ', err);
          resolve(false);
        }
      }
    } else {
      reject(new Error('no support'));
    }
  })
}
// 检查当前编辑环境是否支持command(如'copy')命令
function isCommandSupported(command) {
  return document.queryCommandSupported(command);
}
// 检查是否为微信端浏览
function isWeChatBrowser() {
  var ua = navigator.userAgent.toLowerCase();
  return ua.indexOf('micromessenger') !== -1;
}

export default {
 year,
 companyphone,
 numberList,
 regs,
 hashParam,
 popupClose,
 popupTime,
 popupCloseLive,
 popupTimeLive,
 getHash,
 getServerTime,
 formatString,
 formatNumber,
 formatTime,
 formatThumbs,
 getWeek,
 getSrc,
 IsPC,
 setRandom,
 toSortKey,
 toCompareObject,
 isObjectKeys,
 isArrayIndexs,
 isReallyNaN,
 isTrue,
 isBlank,
 deepClone,
 dataURLToBlob,
 tirggerFile,
 copyText,
 isCommandSupported,
 isWeChatBrowser
}
</script>