// year>=2025
const routes = [
  { path: '/home', name: 'Home', component: () => import( '@/views/Home.vue') },
  { path: '/case', name: 'Case', component: () => import( '@/views/Case.vue') },
  { path: '/speaker', name: 'Speaker', component: () => import( '@/views/Speaker.vue' /* webpackChunkName:"speaker" */ ) },
  { path: '/speaker_', name: 'Speaker_', component: () => import( '@/views/Speaker_.vue' /* webpackChunkName:"speaker" */ ) },
  { path: '/live', name: 'Live', component: () => import( '@/views/Live.vue' /* webpackChunkName:"schedule" */ ) },
  { path: '/live_schedule', name: 'LiveSchedule', component: () => import( '@/views/LiveSchedule.vue' ) },
  { path: '/schedule', name: 'Schedule', component: () => import( '@/views/Schedule.vue') },
  { path: '/schedule_', name: 'Schedule_', component: () => import( '@/views/Schedule__.vue') },
  { path: '/speech', name: 'Speech', component: () => import( '@/views/Speech.vue' /* webpackChunkName:"speech" */ ) },
  { path: '/previous', name: 'Previous', component: () => import( '@/views/Previous.vue' /* webpackChunkName:"previous" */ ) },
  { path: '/teacher', name: 'Teacher', component: () => import( '@/views/Teacher.vue' /* webpackChunkName:"teacher" */ ) },
  { path: '/subject', name: 'Subject', component: () => import( '@/views/Subject.vue' /* webpackChunkName:"subject" */ ) },
  { path: '/course', name: 'Course', component: () => import( '@/views/Course.vue' /* webpackChunkName:"course" */ ) },
  { path: '/ticket', name: 'Ticket', component: () => import( '@/views/Ticket.vue' ), },
  { path: '/order', name: 'Order', component: () => import( '@/views/Order.vue' ), },
  { path: '/login', name: 'Login', component: () => import( '@/views/Login.vue' ), },
  { path: '/userCenter', name: 'UserCenter', component: () => import( '@/views/UserCenter.vue' ), },
]

export default routes;